<template>
  <div
    class="casino-search-result"
    :class="{ mobile: props.isMobile }"
    data-t="search-result"
  >
    <CasinoSearchItem
      v-for="item in props.items"
      :key="item.name"
      :item="item"
      :is-mobile="props.isMobile"
      data-t="game-link"
      :data-game-id="item.gameId"
    />
  </div>
</template>
<script setup lang="ts">
import type { Game } from '../../types'
import CasinoSearchItem from './CasinoSearchItem/CasinoSearchItem.vue'

const props = defineProps<{
  items: Game[]
  isMobile?: boolean
}>()
</script>
<style scoped>
.casino-search-result {
  padding: var(--spacing-050);
  background: var(--background-primary);
  border-radius: var(--border-radius-150);

  &.mobile {
    border-radius: 0;
  }
}
</style>
